
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "admin-credit-notes-table",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      creditNotes: [],
    };
  },
  created() {
    this.getCreditNotes();
    this.displayDatatable();
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getCreditNotes() {
      //Fetch list of all credit notes
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_CREDIT_NOTES_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.creditNotes = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    displayDatatable() {
      setTimeout(() => {
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            zeroRecords: "No matching records found",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
        });
      }, 3000);
    },
  },
});
